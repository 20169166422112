import { Mapper } from '../../utilitis/base/mapper';
import { CityEntitie } from '../entities/city.entitie';
import { CityModel } from '../../domain/models/city.model';

export class CityMapper extends Mapper<
    CityEntitie[] | CityEntitie,
    CityModel[] | CityModel
> {
    mapperArrayFrom(payload: CityEntitie[]): CityModel[] {
        let cityFormat: CityModel[] = [];

        payload.forEach((element: CityEntitie) => {
            cityFormat.push({
                _uuid: element._uuid,
                name: element.name,
            });
        });

        return cityFormat;
    }

    mapperArrayTo(payload: CityModel[]): CityEntitie[] {
        let cityFormat: CityEntitie[] = [];

        payload.forEach((element: CityModel) => {
            cityFormat.push({
                _uuid: element._uuid,
                name: element.name,
            });
        });

        return cityFormat;
    }

    mapperFrom(payload: CityEntitie): CityModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
        };
    }

    mapperTo(payload: CityModel): CityEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
        };
    }
}
