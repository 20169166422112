import { createAction, props } from '@ngrx/store';
import { ProfileModel } from 'src/app/domain/models/profile.model';

export const setProfile = createAction(
    '[ Component set profile] save profile state',
    props<{ profile: ProfileModel }>(),
);

export const refreshToken = createAction('[Service storage] refresh token');
export const refreshTokenSuccess = createAction(
    '[Service storage] refresh token success',
);

export const logOut = createAction('[Component topBar] log out ');
export const logOutSuccess = createAction('[Component topBar] logOut success');
