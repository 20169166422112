import { Mapper } from '../../utilitis/base/mapper';
import { DepartamentEntitie } from '../entities/departament.entitie';
import { DepartamentModel } from '../../domain/models/departament.model';

export class DepartamentMapper extends Mapper<
    DepartamentEntitie[] | DepartamentEntitie,
    DepartamentModel[] | DepartamentModel
> {
    mapperArrayFrom(payload: DepartamentEntitie[]): DepartamentModel[] {
        let departamentFormat: DepartamentModel[] = [];

        payload.forEach((element: DepartamentEntitie) => {
            departamentFormat.push({
                _uuid: element._uuid,
                code: element.state_code,
                name: element.name,
            });
        });

        return departamentFormat;
    }

    mapperFrom(payload: DepartamentEntitie): DepartamentModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            code: payload.state_code ? payload.state_code : '',
        };
    }

    mapperTo(payload: DepartamentModel): DepartamentEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            state_code: payload.code ? payload.code : '',
        };
    }
}
