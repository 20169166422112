import { createAction, props } from '@ngrx/store';
import { DepartamentModel } from 'src/app/domain/models/departament.model';

export const getDepartamentByCountry = createAction(
    '[Component D] get departaments by id country',
    props<{ id: number }>(),
);

export const getDepartamentByCountrySuccess = createAction(
    '[Component D] get departaments by id country success',
    props<{ departaments: DepartamentModel[] }>(),
);
