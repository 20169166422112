import { PersonalShopperPublicModel } from './../../domain/models/personalShopper.model';
import { createAction, props } from '@ngrx/store';
import { BrandPublicModel } from 'src/app/domain/models/brand-public.model';
import { CategoryPublicModel } from 'src/app/domain/models/category-public.model';
import { ClientPublicModel } from 'src/app/domain/models/client.model';
import { ProductGlobalModel } from 'src/app/domain/models/product-global.model';
import { StorePublicModel } from 'src/app/domain/models/store-public.model';


export const getAllCategorysPublic = createAction(
    '[Component z] get all categorys service public',
);

export const getAllCategorysPublicSuccess = createAction(
    '[Component z] get all catrgory service public success',
    props<{ categorys: CategoryPublicModel[] }>(),
);

export const getAllBrandsPublic = createAction(
    '[Component z] get all brands service public',
    props<{store?: string}>()
);

export const getAllBrandsPublicSuccess = createAction(
    '[Component z] get all brands service public success',
    props<{ brands: BrandPublicModel[] }>(),
);

export const getAllStoreMallsPublic = createAction(
    '[Component z] get all store malls service public',
);

export const getAllStoreMallsPublicSuccess = createAction(
    '[Component z] get all store malls service public success',
    props<{ storemalls: StorePublicModel[] }>(),
);

export const getAllProductsGlobal = createAction(
    '[Component z] get all product service public',
    props<{ id?: string }>(),
);

export const getAllProductsGlobalSuccess = createAction(
    '[Component z] get all product service public success',
    props<{ products: ProductGlobalModel[] }>(),
);

export const getAllPersonalShopperGlobal = createAction(
    '[Component z] get all personal shopper ',
);

export const getAllPersonalShopperGlobalSuccess = createAction(
    '[Component z] get all personal shoppersuccess',
    props<{ shoppers: PersonalShopperPublicModel[] }>(),
);

export const getAllClientsGlobal = createAction(
    '[Component z] get all clients',
);

export const getAllClientsGlobalSuccess = createAction(
    '[Component z] get all clients success',
    props<{ clients: ClientPublicModel[] }>(),
);
