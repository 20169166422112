import { Injectable } from '@angular/core';
import * as jose from 'jose';
import { User } from '../utilitis/enums/user';
import { Store } from '@ngrx/store';
import { AppState } from '../storeRoot/states/app.state';
import * as Actions from 'src/app/storeRoot/actions';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    time: any;

    constructor(protected store$: Store<AppState>) {}

    setProfile(profile: any): void {
        const stringify = JSON.stringify(profile);
        const value = window.btoa(stringify);
        localStorage.removeItem(User.USER);
        localStorage.setItem(User.USER, value);
    }

    setToken(token: string): void {
        const stringify = JSON.stringify(token);
        const value = window.btoa(stringify);
        localStorage.removeItem(User.TOKEN);
        localStorage.setItem(User.TOKEN, value);
    }

    getFullName(): string {
        const profile = localStorage.getItem(User.USER);
        if (profile) {
            const profileDecode = window.atob(profile);
            const parseProfile = JSON.parse(profileDecode);
            return `${parseProfile.name} ${parseProfile.lastName}`;
        }
        return '';
    }

    setProfiletState(): void {
        const data = localStorage.getItem(User.USER);
        if (data) {
            const decode = window.atob(data);
            const account = JSON.parse(decode);
            this.store$.dispatch(Actions.setProfile({ profile: account }));
        }
    }

    getToken(): string {
        const data = localStorage.getItem(User.TOKEN);
        if (data) {
            const decode = window.atob(data);
            const token = JSON.parse(decode);
            return token;
        }
        return '';
    }

    isLoggedIn(): boolean {
        const user = localStorage.getItem(User.USER);
        if (user) {
            return true;
        }
        return false;
    }

    tokenExpire(): void {
        const token = this.getToken();

        if (token) {
            const now = new Date();
            const decode = jose.decodeJwt(token);
            const discountMinutes = 20;
            if (decode.exp) {
                const expToken = new Date(decode.exp * 1000);
                const newDateDiscount = expToken.setMinutes(
                    expToken.getMinutes() - discountMinutes,
                );
                const nowDateDiscount = now.setMinutes(
                    now.getMinutes() - discountMinutes,
                );

                if (
                    new Date(nowDateDiscount).getTime() >
                    new Date(newDateDiscount).getTime()
                ) {
                    this.store$.dispatch(Actions.refreshToken());
                    this.destroyVerify();
                }
            }
        }
    }

    verifyToken(): void {
        this.time = setInterval(() => {
            this.tokenExpire();
        }, 1000);
    }

    removeAll() {
        localStorage.removeItem(User.TOKEN);
        localStorage.removeItem(User.USER);
    }

    destroyVerify(): void {
        clearInterval(this.time);
    }
}
