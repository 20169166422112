import { CountryModel } from '../../domain/models/country.model';
import { Mapper } from '../../utilitis/base/mapper';
import { CountryEntitie } from '../entities/country.entitie';

export class CountryMapper extends Mapper<
    CountryEntitie[] | CountryEntitie,
    CountryModel[] | CountryModel
> {
    mapperArrayFrom(payload: CountryEntitie[]): CountryModel[] {
        let countryFormat: CountryModel[] = [];

        payload.forEach((element: CountryEntitie) => {
            countryFormat.push({
                _uuid: element._uuid,
                code: element.iso2,
                name: element.name,
            });
        });

        return countryFormat;
    }

    mapperFrom(payload: CountryEntitie): CountryModel {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            code: payload.iso2 ? payload.iso2 : '',
        };
    }

    mapperTo(payload: CountryModel): CountryEntitie {
        return {
            _uuid: payload._uuid,
            name: payload.name,
            iso2: payload.code,
        };
    }
}
