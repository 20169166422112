import { createAction, props } from '@ngrx/store';
import { CityModel } from 'src/app/domain/models/city.model';

export const getAllCitysByDepartament = createAction(
    '[Component C] get all citys by id departament',
    props<{ id: number }>(),
);

export const getAllCitysByDepartamentSuccess = createAction(
    '[Component C] get all citys by id departament success',
    props<{ citys: CityModel[] }>(),
);
